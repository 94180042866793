import { NavLink } from 'react-router-dom';

import { contacts } from '../../static/contacts';
import './footer.scss';

const Footer = () => (
  <footer className="page-footer">
    <div className="container">
      <NavLink to="/" className="page-footer__logo">
        <img src="/assets/images/logos/logo.svg" alt="Logo" />
      </NavLink>

      <div className="page-footer__contacts">
        <a href={`tel:${contacts.phone}`} className="page-footer__contacts--phone">
          {contacts.phone}
        </a>
        <a href={`mailto:${contacts.email}`} className="page-footer__contacts--email">
          {contacts.email}
        </a>
        <div className="page-footer__contacts--address">
          <span>{contacts.address.street}</span>
          <br />
          <span>
            {contacts.address.city} {contacts.address.state} {contacts.address.zip_code}
          </span>
        </div>
      </div>
    </div>
    <Underline />
  </footer>
);

export default Footer;

const Underline = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="page-footer__underline">
      <div className="container">
        <span>©{currentYear} Pacific Coast Air. All Rights Reserved.</span>

        <div className="page-footer__underline--links">
          <NavLink to="/privacy-policy">Privacy Policy</NavLink>

          <NavLink to="/accessibility-statement">Accessibility Statement</NavLink>

          <NavLink to="/terms-of-use">Terms of Use</NavLink>
        </div>
      </div>
    </div>
  );
};
