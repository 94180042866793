import React from 'react';
import useFetch from 'react-fetch-hook';
import { toast } from 'react-toastify';

import { Layout, Seo, Spinner, ContentBlock } from '../components';

const adminURL = 'https://admin.pacificcoastairpro.com/wp-json/wp/v2/posts?slug=privacy-policy';

const PrivacyPolicy = () => {
  const [pageData, setPageData] = React.useState();

  const { isLoading, data, error } = useFetch(adminURL);

  React.useEffect(() => {
    if (data) {
      setPageData(data[0]);
    }
  }, [data]);

  React.useEffect(() => {
    if (error) toast.error('Data loading error. Please reload the page!');
  }, [error]);

  if (isLoading) return <Spinner />;

  return (
    <Layout>
      <Seo />
      <section>
        <ContentBlock title="Privacy Policy" content={pageData?.content.rendered} />
      </section>
    </Layout>
  );
};

export default PrivacyPolicy;
