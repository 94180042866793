import './pageContent.scss';

const PageContent = ({ data }) => {
  let title = `#1 <span>${data.title}</span> Service<br />in San Diego`;

  if (data.slug === 'hvac-services') {
    title = `#1 <span>HVAC Services</span><br/>in San Diego`;
  }

  return (
    <section className="page-content">
      <div className="container">
        <div className="page-content__content">
          <h2 className="section-title" dangerouslySetInnerHTML={{ __html: title }} />

          <div className="page-content__text" dangerouslySetInnerHTML={{ __html: data.content }} />
        </div>

        <div className="page-content__image">
          <img src={data.image} alt="page-content" />
        </div>
      </div>
    </section>
  );
};

export default PageContent;
