import { NavLink } from 'react-router-dom';
import { contacts } from '../../static/contacts';

import './callUs.scss';

const CallUs = () => (
  <section className="call-us">
    <div className="container">
      <h2 className="section-title">
        Need Your
        <br />
        HVAC System Fixed?
      </h2>

      <div className="call-us__links">
        <NavLink to="/book" className="action-button" state={{ prevLocation: window.location.pathname.split('/')[1] }}>
          Book a Service
        </NavLink>

        <span>
          or call us <a href={`tel:${contacts.phone}`}>{contacts.phone}</a>
        </span>
      </div>
    </div>
  </section>
);

export default CallUs;
