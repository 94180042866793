import './satisfaction.scss';

const links = [
  {
    logo: '/assets/images/logos/google.svg',
    name: 'Google',
    reviews: 102,
  },
  {
    logo: '/assets/images/logos/facebook.svg',
    name: 'Facebook',
    reviews: 256,
  },
  {
    logo: '/assets/images/logos/yelp.svg',
    name: 'Yelp',
    reviews: 202,
  },
];

const Satisfaction = () => (
  <section className="satisfaction">
    <div className="container">
      <h2 className="section-title">
        <span>100%</span> Satisfaction
        <br />
        Guaranteed
      </h2>

      <div className="satisfaction__links">
        {links.map((el, index) => (
          <SatisfactionCard key={index} data={el} />
        ))}
      </div>
    </div>
  </section>
);

export default Satisfaction;

const SatisfactionCard = ({ data }) => (
  <div className="satisfaction__card">
    <div className="satisfaction__card--stars">
      <img src="/assets/images/icons/star.svg" alt="star icon" />
      <img src="/assets/images/icons/star.svg" alt="star icon" />
      <img src="/assets/images/icons/star.svg" alt="star icon" />
      <img src="/assets/images/icons/star.svg" alt="star icon" />
      <img src="/assets/images/icons/star.svg" alt="star icon" />
    </div>
    <div className="satisfaction__card--reviews">
      <div className="satisfaction__card--reviews__logo">
        <img src={data.logo} alt={data.name} />
      </div>
      <span>{data.reviews} Reviews</span>
    </div>
  </div>
);
