import { contacts } from '../../static/contacts';

import './whyWe.scss';

const expertise = [
  {
    icon: '/assets/images/icons/wallet.svg',
    title: 'NO EXTRA CHARGE',
    text: 'We have no hidden costs or charges.',
  },
  {
    icon: '/assets/images/icons/percent.svg',
    title: '100% GUARANTEE',
    text: 'We offer a labor warranty on our installation, repair, and services.',
  },
  {
    icon: '/assets/images/icons/service.svg',
    title: 'OUTSTANDING SERVICES',
    text: 'We commit a customer service department to you.',
  },
  {
    icon: '/assets/images/icons/idea.svg',
    title: 'ENERGY EFFICIENCY',
    text: 'The most eco-friendly and efficient options.',
  },
];

const WhyWe = ({ title }) => (
  <section className="why-we">
    <div className="container">
      <h2 className="section-title">
        <span>Why</span> Pacific Coast Air?
      </h2>

      <div className="why-we__content">
        <div className="why-we__content--expertise">
          {expertise.map((el, index) => (
            <ExpertiseCard key={index} data={el} />
          ))}
        </div>

        <div className="why-we__content--c2a">
          <div className="why-we__content--c2a__header">
            <img src="/assets/images/AdobeStock_150390156.jpeg" alt="We are the best HVAC Solution for you" />

            <p>We are the best HVAC Solution for you</p>
          </div>

          <Coupon />
        </div>
      </div>
    </div>
  </section>
);

export default WhyWe;

const ExpertiseCard = ({ data }) => (
  <div className="why-we__content--expertise__card">
    <div className="why-we__content--expertise__card--icon">
      <img src={data.icon} alt={data.title} />
    </div>
    <h4 className="why-we__content--expertise__card--title">{data.title}</h4>
    <p>{data.text}</p>
  </div>
);

const Coupon = () => (
  <div className="why-we__content--c2a__coupon">
    <div>
      <h3>
        <span>$79</span>
        <br />
        A/C and Heating Systems Tune-Up
      </h3>
      <p>Always Low Cost & High Quality Service</p>
      <span>Call now!</span>
      <a href={`tel:${contacts.phone}`} className="action-button">
        {contacts.phone}
      </a>
    </div>
  </div>
);
