export const contacts = {
  phone: '(619) 837-7759',
  email: 'pacificcoastairinc1@gmail.com',
  address: {
    street: '3830 Valley Centre Dr #705/855',
    city: 'San Diego',
    state: 'CA',
    zip_code: 92130,
  },
};
