import React from 'react';
import { useParams } from 'react-router-dom';
import useFetch from 'react-fetch-hook';
import { toast } from 'react-toastify';

import { Layout, Seo, Spinner } from '../components';
import {
  Hero,
  WhyWe,
  GetInTouch,
  Satisfaction,
  CallUs,
  PageContent,
  Services,
  Reviews,
  Areas,
  CallUs2,
} from '../sections';

const adminURL = 'https://admin.pacificcoastairpro.com/wp-json/wp/v2';

const PageTemplate = () => {
  const { slug } = useParams();
  const [pageData, setPageData] = React.useState();

  const { isLoading, data, error } = useFetch(`${adminURL}/pages?slug=${slug}`);

  React.useEffect(() => {
    if (data) {
      setPageData(data[0]);
    }
  }, [data]);

  React.useEffect(() => {
    if (error) toast.error('Data loading error. Please reload the page!');
  }, [error]);

  if (isLoading) return <Spinner />;

  return (
    <Layout pageId={pageData?.slug}>
      <Seo data={pageData?.yoast_head_json} />
      <Hero title={pageData?.acf?.page_title} id={pageData?.slug} />
      <WhyWe />
      <GetInTouch />
      <Satisfaction />
      <CallUs />
      <PageContent
        data={{
          content: pageData?.content?.rendered,
          image: pageData?.acf?.main_image,
          title: pageData?.acf?.page_title,
          slug,
        }}
      />
      <Services />
      <Reviews data={pageData?.acf?.reviews} />
      <Areas />
      <CallUs2 />
    </Layout>
  );
};

export default PageTemplate;
