import React from 'react';

import { FeedbackForm } from '../../components';

import './getInTouch.scss';

const GetInTouch = () => {
  return (
    <section className="get-in-touch">
      <div className="container">
        <div>
          <h2 className="section-title">
            <span>Contact</span> Our Specialists
          </h2>

          <p>Tell us how we can help you today</p>

          <div className="img-wrapper">
            <img src="/assets/images/image-1.svg" alt="Contact Our Specialists" />
          </div>
        </div>

        <div className="get-in-touch__form-wrapper">
          <FeedbackForm />
        </div>
      </div>
    </section>
  );
};

export default GetInTouch;
