import { NavLink } from 'react-router-dom';

import './services.scss';

const services = [
  {
    icon: '/assets/images/services/ac_repair.svg',
    title: 'Air Conditioning Repair',
    link: '/air-conditioning-repair',
  },
  {
    icon: '/assets/images/services/ac_installation.svg',
    title: 'Air Conditioning Installation & Replacement',
    link: '/air-conditioning-installation-replacement',
  },
  {
    icon: '/assets/images/services/heating_repair.svg',
    title: 'Heating Repair',
    link: '/heating-repair',
  },
  {
    icon: '/assets/images/services/heating_installation.svg',
    title: 'Heating Installation & Replacement',
    link: '/heating-installation-replacement',
  },
  {
    icon: '/assets/images/services/furnace_repair.svg',
    title: 'Furnace Repair & Installation',
    link: '/furnace-repair-installation',
  },
  {
    icon: '/assets/images/services/hvac.svg',
    title: 'HVAC System Tune-Up',
    link: '/hvac-maintenance',
  },
];

const Services = () => (
  <section className="services">
    <div className="container">
      <h2 className="section-title">
        <span>
          Heating & Air
          <br />
          Conditioning
        </span>{' '}
        services
      </h2>

      <div className="services__grid">
        {services.map((el, index) => (
          <GridItem key={index} data={el} />
        ))}
      </div>
    </div>
  </section>
);

export default Services;

const GridItem = ({ data }) => (
  <NavLink to={data.link} className="services__grid--item">
    <div className="services__grid--item__icon">
      <img src={data.icon} alt={data.title} />
    </div>
    <h4 className="services__grid--item__title">{data.title}</h4>
  </NavLink>
);
