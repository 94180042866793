import React from 'react';
import { NavLink } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Header, Footer } from '../';

import './layout.scss';

import { contacts } from '../../static/contacts';

const Layout = ({ pageId, children }) => {
  const [isVisible, setVisibility] = React.useState(false);

  const handleScroll = () => {
    setVisibility(window.scrollY > 200);
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div id={pageId}>
      <Header />
      <main>{children}</main>
      <Footer />
      <ToastContainer />
      <div className={`mobile-cta-buttons ${isVisible ? 'on' : 'off'}`}>
        <a href={`tel:${contacts.phone}`}>{contacts.phone}</a>
        <NavLink to="/book" state={{ prevLocation: window.location.pathname.split('/')[1] }}>
          Schedule a Service
        </NavLink>
      </div>
    </div>
  );
};

export default Layout;
