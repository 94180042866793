import { contacts } from '../../static/contacts';

import './callUs2.scss';

const CallUs2 = () => (
  <section className="call-us-two">
    <div className="container">
      <h2 className="section-title">
        DON’T HESITATE TO REACH OUT
        <br />
        TO OUR <span>PACIFIC AIR COAST</span>
      </h2>
      <p>FOR YOUR HEATING AND AIR CONDITIONING SERVICE IN SAN DIEGO CA</p>

      <div className="call-us-two__content">
        <div className="call-us-two__links">
          <a href={`tel:${contacts.phone}`} className="action-button">
            {contacts.phone}
          </a>
          <span>Call us now!</span>
        </div>
        <div className="call-us-two__payments">
          <img src="/assets/images/logos/mastercard.svg" alt="mastercard" />
          <img src="/assets/images/logos/visa.svg" alt="visa" />
          <img src="/assets/images/logos/discover.svg" alt="discover" />
          <img src="/assets/images/logos/american_express.svg" alt="american express" />
        </div>
      </div>
    </div>
  </section>
);

export default CallUs2;
