import { NavLink } from 'react-router-dom';
import { contacts } from '../../static/contacts';

import './hero.scss';

const awards = [
  '/assets/images/logos/awards_badge_1.svg',
  '/assets/images/logos/awards_badge_2.svg',
  '/assets/images/logos/awards_badge_3.svg',
  '/assets/images/logos/awards_badge_4.svg',
  '/assets/images/logos/awards_badge_5.svg',
];

const Hero = ({ title, id }) => (
  <section className="hero">
    <div className="container">
      <div className="hero__image">
        <div className="hero__image--main">
          <img src="/assets/images/happy-family-sitting-couch-living-room.png" alt="hero" />
        </div>
        <div className="hero__image--additional">
          <img src="/assets/images/ac.png" alt="hero" />
        </div>
      </div>
      <div className="hero__content">
        <h1>
          {id === 'hvac-maintenance' ? '$79 A/C and Heating Systems Tune-Up' : title}
          <br />
          in San Diego
        </h1>
        <ul>
          <li>Free Service Call*</li>
          <li>Same Day Service</li>
          <li>Transparent Pricing</li>
        </ul>
        <div className="hero__content--awards">
          {awards.map((el, index) => (
            <div className="hero__content--awards__item" key={index}>
              <img src={el} alt={`award-${index}`} />
            </div>
          ))}
        </div>
        <div className="hero__content--call_us">
          <NavLink
            to="/book"
            className="action-button"
            state={{ prevLocation: window.location.pathname.split('/')[1] }}
          >
            Book a Service
          </NavLink>
          <span>
            or call us <a href={`tel:${contacts.phone}`}>{contacts.phone}</a>
          </span>
        </div>
      </div>
    </div>
  </section>
);

export default Hero;
