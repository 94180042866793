import React from 'react';
import { NavLink, Link } from 'react-router-dom';

import { contacts } from '../../static/contacts';

import './header.scss';

const Header = () => {
  const [shadow, setShadow] = React.useState(false);

  const handleScroll = () => {
    setShadow(window.scrollY > 142);
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header className={`page-header ${shadow && 'shadow'}`}>
      <div className="container">
        <Link to="/" className="page-header__logo">
          <img src="/assets/images/logos/logo.svg" alt="Logo" />
        </Link>

        <div className="page-header__links">
          <a href={`tel:${contacts.phone}`} className="page-header__phone">
            {contacts.phone}
          </a>
          <NavLink
            to="/book"
            className="action-button"
            state={{ prevLocation: window.location.pathname.split('/')[1] }}
          >
            Book a Service
          </NavLink>
        </div>
      </div>
    </header>
  );
};

export default Header;
